import React from "react";
import logo from "../img/logo.jpeg";

function Loading({ text = "" }) {
  return (
    <div
      className="loading-screen d-flex flex-column justify-content-center align-items-center"
      style={{ height: window.innerHeight }}
    >
      <div
        className="rounded-circle fade-in"
        style={{ height: "80px", width: "80px" }}
      >
        <img
          src={logo}
          alt=""
          className="rounded-circle p-2 bg-orange"
          height="70px"
          width="70px"
        />
      </div>
      <div className=" text-muted mt-3">{text}</div>
    </div>
  );
}

export default Loading;

// parle, dr.oatker, funfoods, tissue, tata, taj, rajdhani, shaktibhog, cadbury, mdh, harpic, lizol, genda-phenyl
