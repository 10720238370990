const initialState = {
  vendorList: null,
};

export const vendor = (state = initialState, { type, data }) => {
  let vendorIndex;
  let vendor;

  switch (type) {
    case "SAVE_NEW_VENDOR":
      vendor = state.vendorList;
      data = vendor?.length
        ? data?.filter(
            (d) => vendor?.findIndex((v) => v.vendorId === d.vendorId) === -1
          )
        : data;
      return { ...state, vendorList: [...(state.vendorList || []), ...data] };

    case "SAVE_VENDOR_PRODUCTS":
      vendor = state.vendorList;
      vendorIndex = state.vendorList?.findIndex(
        (el) => el.vendorId === data.vendorId
      );
      if (vendorIndex !== undefined && vendorIndex !== -1) {
        vendor = [
          ...vendor.slice(0, vendorIndex),
          { ...vendor[vendorIndex], products: data.products },
          ...vendor.slice(vendorIndex + 1, vendor?.length),
        ];
      }
      return { ...state, vendorList: vendor };

    default:
      return state;
  }
};
