const initialState = {
  categoryDropdown: false,
};

export const dropdown = (state = initialState, { type }) => {
  switch (type) {
    case "OPEN_CAT-DD":
      return { categoryDropdown: true };

    case "CLOSE_CAT-DD":
      return { categoryDropdown: false };

    default:
      return state;
  }
};
export const openCatDD = () => {
  return { type: "OPEN_CAT-DD" };
};
export const closeCatDD = () => {
  return { type: "CLOSE_CAT-DD" };
};
