import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
require("dotenv").config();

const firebaseConfig = {
  apiKey: process.env.React_App_apiKey,
  authDomain: process.env.React_App_authDomain,
  projectId: process.env.React_App_projectId,
  storageBucket: process.env.React_App_storageBucket,
  messagingSenderId: process.env.React_App_messagingSenderId,
  appId: process.env.React_App_appId,
};

// Initialize Firebase
//eslint-disable-next-line
const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export { db };
// change
// new