import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Loading from "./Others/Loading";
import enTranslation from "./locales/en/translation.json";
import hiTranslation from "./locales/hi/translation.json";
import paTranslation from "./locales/pa/translation.json";
import udTranslation from "./locales/ud/translation.json";
import "./index.css";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import store from "./Redux/ReduxStore";
import { QueryClient, QueryClientProvider } from "react-query";

const Buyer = lazy(() => import("./Buyer"));
const queryClient = new QueryClient();
const resources = {
  en: {
    translation: enTranslation,
  },
  hi: {
    translation: hiTranslation,
  },
  pa: {
    translation: paTranslation,
  },
  ud: {
    translation: udTranslation,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  //.use(LanguageDetector)
  //.use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,
    fallbackLng: "en",
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
  });

ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Toaster />
        <Provider store={store}>
          <Buyer />
        </Provider>
      </QueryClientProvider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);
