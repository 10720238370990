import { db } from "../../Firebase/firebase";

const initialState = {
  info: null,
  address: null,
  cart: null,
  currentLocation: null,
  utmLoad: true,
  bill: null,
  onlinePaymentDetails: {
    paymentMethod: null,
    paytmOrderId: null,
    paytmTxnNo: null,
  },
};

export const user = (state = initialState, { type, data }) => {
  let tempCart, vendorIndex, vendorCart, productId, product;
  switch (type) {
    case "SAVE_USER":
      return { ...state, info: data };
    case "SAVE_BILL_CHECKOUT":
      return { ...state, bill: data };
    case "ONLINE_PAYMENT_DETAILS":
      return {
        ...state,
        onlinePaymentDetails: {
          ...state.onlinePaymentDetails,
          ...data,
        },
      };
    case "CLEAR_ONLINE_PAYMENT_DETAILS":
      return {
        ...state,
        onlinePaymentDetails: null,
      };
    case "SAVE_ADDRESS":
      return { ...state, address: data };

    case "SET_CURR_LOCATION":
      return { ...state, currentLocation: data };

    case "FETCH_CART":
      return { ...state, cart: data };

    case "CLEAR_ALL":
      return { info: null, address: null, cart: null, currentLocation: null };

    case "ADD_TO_CART":
      tempCart = state.cart;

      // Find Vendor in cart
      vendorIndex = tempCart.findIndex((v) => v?.vendorId === data?.vendorId);

      // If Vendor found
      if (vendorIndex !== -1) {
        // Set Vendor Cart
        vendorCart = tempCart[vendorIndex].cart;

        // Search for product
        productId = vendorCart.findIndex((p) => p.pID === data?.pID);

        // If Product is found
        if (productId !== -1) {
          vendorCart = [
            ...vendorCart.slice(0, productId),
            {
              ...vendorCart[productId],
              qty: vendorCart[productId].qty + 1,
              finalSp: data.finalSp,
              free: data.free,
            },
            ...vendorCart.slice(productId + 1, vendorCart.length),
          ];

          tempCart[vendorIndex] = {
            ...tempCart[vendorIndex],
            cart: vendorCart,
          };
        }

        // If Product not found
        else {
          vendorCart = [
            ...vendorCart,
            {
              pID: data.pID,
              sp: data.sp,
              finalSp: data.finalSp,
              pName: data.pName,
              qty: 1,
              mrp: data.mrp,
              free: data.free,
              free_item: data.free_item,
              productImg: data.productImg,
              // productSize: data.newProductSize,
              subCat: data?.subCat,
            },
          ];

          tempCart[vendorIndex] = {
            ...tempCart[vendorIndex],
            cart: vendorCart,
          };
        }
      }

      // If Vendor not found
      else {
        tempCart = [
          ...tempCart,
          {
            vendorId: data?.vendorId,
            cart: [
              {
                pID: data.pID,
                sp: data.sp,
                finalSp: data.finalSp,
                qty: 1,
                pName: data.pName,
                mrp: data.mrp,
                free: data.free,
                free_item: data.free_item,
                productImg: data.productImg,
                subCat: data?.subCat,
                // productSize: data.newProductSize,
              },
            ],
          },
        ];
      }
      if (state.info?.userHash)
        db.collection("Users")
          .doc(state.info?.userHash)
          .set({ cart: tempCart });
      else localStorage.setItem("cart", JSON.stringify(tempCart));

      return { ...state, cart: tempCart };

    case "REMOVE_FROM_CART":
      // Set temp cart
      tempCart = state.cart;

      // Find Vendor Cart
      vendorIndex = tempCart.findIndex((v) => v?.vendorId === data?.vendorId);

      // If Vendor is found
      if (vendorIndex !== -1) {
        // Set Vendor Cart
        vendorCart = tempCart[vendorIndex].cart;

        // Search for product
        productId = vendorCart.findIndex((p) => p.pID === data?.pID);

        // If Product Found
        if (productId !== -1) {
          // If last product, splice the cart array
          product = vendorCart[productId];
          if (product.qty === 1) {
            vendorCart = [
              ...vendorCart.slice(0, productId),
              ...vendorCart.slice(productId + 1, vendorCart.length),
            ];
          } else {
            vendorCart = [
              ...vendorCart.slice(0, productId),
              {
                ...vendorCart[productId],
                qty: vendorCart[productId].qty - 1,
                finalSp: data.finalSp,
                free: data.free,
              },
              ...vendorCart.slice(productId + 1, vendorCart.length),
            ];
          }
          tempCart[vendorIndex] = {
            ...tempCart[vendorIndex],
            cart: vendorCart,
          };
        }

        tempCart = tempCart.filter((el) => el?.cart?.length);

        if (state.info?.userHash)
          db.collection("Users")
            .doc(state.info?.userHash)
            .set({ cart: tempCart });
        else localStorage.setItem("cart", JSON.stringify(tempCart));

        return { ...state, cart: tempCart };
      }
      return state;

    case "CLEAR_CART":
      tempCart = state.cart;
      tempCart = tempCart.filter((el) => el.vendorId !== data);
      if (state.info?.userHash)
        db.collection("Users")
          .doc(state.info?.userHash)
          .set({ cart: tempCart });
      else localStorage.setItem("cart", JSON.stringify(tempCart));

      return { ...state, cart: tempCart };

    case "UTM_LOAD":
      return { ...state, utmLoad: false };

    default:
      return state;
  }
};
